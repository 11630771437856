#loader {
  width           : 100vw;
  height          : 100vh;
  position        : absolute;
  background-color: rgba(0, 0, 0, 0.2);
  z-index         : 100;
  display         : flex;
  flex-direction  : column;
  align-content   : center;
  justify-content : center;

  >span {
    text-align: center;
    font-size : 40px;
  }
}