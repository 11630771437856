@import './themes.less';
@import './loader.less';
@import './topmenu.less';
@import './bottommenu.less';
@import './pagenavigationmenu.less';
@import './partmenu-sliderbuttons.less';

body {
  // background-image      : url(../../assets/ui/pageBg.jpg);
  overflow                 : hidden;
  background-color         : aliceblue;
  // background-color      : yellow;
}

// easeljs canvas
#groovy {
  // height        : calc(~"100vh - 200px");
  // width         : 100vw;
  margin           : 0 2px;
  height           : calc(~"100vh - 104px - 90px");
  width            : calc(~"100vw - 4px");
  vertical-align   : bottom;
}


// debug only
#controls {
  min-height      : 40px;
  background-color: yellow;
}