.shapes-en-us {
  background: url(../../assets/shapes/ui-en-us.png);
}

.shapes-zh-hans {
  background: url(../../assets/shapes/ui-zh-hans.png);
}

.shapes-zh-hant {
  background: url(../../assets/shapes/ui-zh-hant.png);
}

.jungle-en-us {
  background: url(../../assets/jungle/ui-en-us.png);
}

.jungle-zh-hans {
  background: url(../../assets/jungle/ui-zh-hans.png);
}

.jungle-zh-hant {
  background: url(../../assets/jungle/ui-zh-hant.png);
}

.city-en-us {
  background: url(../../assets/city/ui-en-us.png);
}

.city-zh-hans {
  background: url(../../assets/city/ui-zh-hans.png);
}

.city-zh-hant {
  background: url(../../assets/city/ui-zh-hant.png);
}