#topmenu {
  background-image: url(../../assets/ui/pageBg.jpg);
  display         : flex;
  flex-direction  : row;
  justify-content : space-around;
  width           : 100vw;
  position        : relative;
  z-index         : 2;

  >div {
    margin-top: 10px;
    cursor    : pointer;

    &:nth-child(odd) {
      margin-top         : 20px;
      // background-color: rgba(255, 0, 0, 0.5)
    }
  }

  .scaleAll(1.2);
}

@media screen and (max-width: 1280px) {
  #topmenu {
    .scaleAll(1);
  }
}

@media screen and (max-width: 940px) {
  #topmenu {
    .scaleAll(0.7);
  }
}

@media screen and (max-width: 600px) {
  #topmenu {
    .scaleAll(0.5);
  }
}

@media screen and (max-width: 360px) {
  #topmenu {
    .scaleAll(0.4);
  }
}

.scaleAll(@scale) {
  height: calc(~"@{scale} * 100px");


  >div {
    background-size: calc(~"@{scale} * 1024px") calc(~"@{scale} * 578px");
    width          : calc(~"@{scale} * 71px");
    height         : calc(~"@{scale} * 72px");

    &:nth-child(odd) {
      margin-top : calc(~"@{scale} * 20px");
    }
  }

  .logo {
    .setBackgroundLogo(@scale);
  }

  .sfx {
    .setBackground('sfx', @scale);
  }

  .melody {
    .setBackground('melody', @scale);
  }

  .bass {
    .setBackground('bass', @scale);
  }

  .chord {
    .setBackground('chord', @scale);
  }

  .arpeggio {
    .setBackground('arpeggio', @scale);
  }

  .rhythm {
    .setBackground('rhythm', @scale);
  }

  .tempo {
    .setBackground('tempo', @scale);
  }

  .instruments {
    .setBackground('instruments', @scale);
  }

  .dynamics {
    .setBackground('dynamics', @scale);
  }

  .chromatics {
    .setBackground('chromatics', @scale);
  }


}

.setBackgroundLogo(@scale) {
  @x1                : 134;
  @y1                : 75;
  @x2                : -377;
  @y2                : -401;
  background-position: 0 0;
  width              : calc(~"@{scale} * @{x1}px");
  height             : calc(~"@{scale} * @{y1}px");
  align-self         : center;
  justify-self       : flex-start;
  margin-top         : 0px !important;

  &:hover {
    background-position: calc(~"@{scale} * @{x2}px") calc(~"@{scale} * @{y2}px");
  }
}


.setBackgroundRoundButtons(@scale, @x1, @y1, @x2, @y2) {
  background-position: calc(~"@{scale} * @{x1}px") calc(~"@{scale} * @{y1}px");

  &:hover,
  &.selected {
    background-position: calc(~"@{scale} * @{x2}px") calc(~"@{scale} * @{y2}px");
  }
}

.setBackground(@type, @scale) when (@type ='sfx') {
  .setBackgroundRoundButtons(@scale, -135, 0, -135, -75);
}

.setBackground(@type, @scale) when (@type ='melody') {
  .setBackgroundRoundButtons(@scale, -206, 0, -206, -75);
}

.setBackground(@type, @scale) when (@type ='bass') {
  .setBackgroundRoundButtons(@scale, -276, 0, -276, -75);
}

.setBackground(@type, @scale) when (@type ='chord') {
  .setBackgroundRoundButtons(@scale, -347, 0, -347, -75);
}

.setBackground(@type, @scale) when (@type ='arpeggio') {
  .setBackgroundRoundButtons(@scale, -418, 0, -418, -75);
}

.setBackground(@type, @scale) when (@type ='rhythm') {
  .setBackgroundRoundButtons(@scale, -488, 0, -488, -75);
}

.setBackground(@type, @scale) when (@type ='tempo') {
  .setBackgroundRoundButtons(@scale, -560, 0, -560, -75);
}

.setBackground(@type, @scale) when (@type ='instruments') {
  .setBackgroundRoundButtons(@scale, -630, 0, -630, -75);
}

.setBackground(@type, @scale) when (@type ='dynamics') {
  .setBackgroundRoundButtons(@scale, -701, 0, -701, -75);
}

.setBackground(@type, @scale) when (@type ='chromatics') {
  .setBackgroundRoundButtons(@scale, -135, -147, -489, -147);
}