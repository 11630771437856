#partmenu-sliderbuttons {
  display         : flex;
  flex-direction  : row;
  justify-content : space-between;
  background-color: rgba(255, 0, 0, 0.3);
  position        : absolute;
  width           : 100vw;
  height          : 0px;
  top             : 0px;
  z-index         : 1;

  &.slide-up {
    animation: slide-up 0.3s linear; // will be overruled by React!
  }

  &.slide-down {
    animation: slide-down 0.3s linear;
  }

  div {

    &.slide-left,
    &.slide-right {
      display: inline-block;
      cursor : pointer;
    }

    &.slide-left {
      width              : 28px;
      height             : 26px;
      background-position: -31px -127px;

      &:hover {
        background-position: -87px -101px;
      }
    }

    &.slide-right {
      width              : 28px;
      height             : 25px;
      background-position: -3px -127px;

      &:hover {
        background-position: -2px -101px;
      }
    }

    &.nav-left,
    &.nav-right {
      display            : flex;
      height             : 50px;
      width              : 50px;
      // background-color: #9cbcc4;
      flex-direction     : column;
      justify-content    : center;
      align-items        : center;
    }

    &.hidden {
      visibility: hidden;
    }
  }


  @keyframes slide-up {
    0% {
      top: 0px;
    }

    100% {
      top: -50px;
    }
  }

  @keyframes slide-down {
    0% {
      top: -50px;
    }

    100% {
      top: 0px;
    }
  }

  .scaleAll3(1.2);
}

@media screen and (max-width: 1280px) {
  #partmenu-sliderbuttons {
    .scaleAll3(1);
  }
}

@media screen and (max-width: 940px) {
  #partmenu-sliderbuttons {
    .scaleAll3(0.7);
  }
}

@media screen and (max-width: 600px) {
  #partmenu-sliderbuttons {
    .scaleAll3(0.5);
  }
}

@media screen and (max-width: 360px) {
  #partmenu-sliderbuttons {
    .scaleAll3(0.4);
  }
}

.scaleAll3(@scale) {
  margin-top : calc(~"@{scale} * 100px");

  >div {

    &.nav-left,
    &.nav-right {
      height : calc(~"@{scale} * 90px");
    }
  }
}