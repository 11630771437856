#pagenavigationmenu {
  display         : flex;
  flex-direction  : row;
  justify-content : space-between;
  background-color: rgba(255, 0, 0, 0.3);
  position        : absolute;
  width           : 100vw;
  height          : 0px;
  top             : 0px;
  z-index         : 1;

  &.slide-up {
    animation: slide-up 0.3s ease;
  }

  &.slide-down {
    animation: slide-down 0.3s ease;
  }

  div {
    display: inline-block;
    cursor : pointer;
    margin : 5px;

    &.next_bar {
      width              : 28px;
      height             : 25px;
      background-position: -32px -75px;

      &:hover {
        background-position: -32px -101px;
      }
    }

    &.next_page {
      width : 28px;
      height: 26px;

      background-position: -2px -75px;

      &:hover {
        background-position: -2px -101px;
      }
    }

    &.prev_bar {
      width              : 27px;
      height             : 26px;
      background-position: -60px -75px;

      &:hover {
        background-position: -60px -101px;
      }
    }

    &.prev_page {
      width              : 25px;
      height             : 26px;
      background-position: -87px -75px;

      &:hover {
        background-position: -87px -101px;
      }
    }
  }

  .nav_left {
    margin-left: 20px;
  }

  .nav_right {
    margin-right: 20px;
  }

  @keyframes slide-up {
    0% {
      top: 0px;
    }

    100% {
      top: -40px;
    }
  }

  @keyframes slide-down {
    0% {
      top: -40px;
    }

    100% {
      top: 0px;
    }
  }

  .scaleAll2(1.2);
}

@media screen and (max-width: 1280px) {
  #pagenavigationmenu {
    .scaleAll2(1);
  }
}

@media screen and (max-width: 940px) {
  #pagenavigationmenu {
    .scaleAll2(0.7);
  }
}

@media screen and (max-width: 600px) {
  #pagenavigationmenu {
    .scaleAll2(0.5);
  }
}

@media screen and (max-width: 360px) {
  #pagenavigationmenu {
    .scaleAll2(0.4);
  }
}

.scaleAll2(@scale) {
  >div {
    margin-top: calc(~"@{scale} * 100px + 10px");
  }
}