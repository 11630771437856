#bottommenu {
  display               : flex;
  flex-direction        : row;
  justify-content       : center;
  align-items           : center;
  width                 : 100vw;
  height                : 90px;
  // background-position: 0 -485px;
  background-image      : url(../../assets/ui/pageBg.jpg);

  // margin-top                             : -100px;
  // transition                             : margin-top .3s;
  // z-index                                : 1;

  // &.slide-up {
  //   animation: slide-up 0.3s ease;
  // }

  // &.slide-down {
  //   animation: slide-down 0.3s ease;
  // }
  #transport {
    // align-self         : center;
    background-position   : 0 -224px;
    width                 : 166px;
    height                : 59px;
    display               : flex;
    justify-content       : space-around;
    align-items           : flex-start;

    >div {
      margin-top: 6px;

      &:last-child {
        margin-right: 6px;
      }
    }

    #rewind {
      width              : 38px;
      height             : 37px;
      background-position: 0px -153px;
      cursor             : pointer;

      &:hover {
        background-position: 0px -189px;
      }
    }

    #play {
      width              : 27px;
      height             : 36px;
      background-position: -37px -153px;
      cursor             : pointer;

      &:hover {
        background-position: -37px -188px;
      }
    }

    #stop {
      width              : 33px;
      height             : 36px;
      background-position: -64px -153px;
      cursor             : pointer;

      &:hover {
        background-position: -64px -188px;
      }
    }
  }

  #pointer {
    justify-self: self-end;
  }
}

@media screen and (max-width: 1280px) {}

@media screen and (max-width: 940px) {}

@media screen and (max-width: 600px) {}

@media screen and (max-width: 360px) {}